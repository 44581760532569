import apiServiceAsync from '@/api/apiServiceAsync';

const api = await apiServiceAsync();
export default {
  setForms({ commit }, forms) {
    commit('setApplicationFormsNew', forms);
  },
  setFormsSettings({ commit }, settings) {
    commit('setApplicationFormSettings', settings);
  },
  setFormCredentials({ commit }, credentials) {
    commit('setApplicationFormCredentials', credentials);
  },
  startNewApplication({ commit }, { uuids, lang }) {
    if (lang) commit('setLanguage', lang, { root: true });
    // uuids query params can be single or multiple uuids separated with comma
    uuids = Array.isArray(uuids) ? uuids.join(',') : uuids;
    const config = {
      params: {
        uuids,
      },
    };
    return api.get('api/v2/applications/new', config).then((response) => {
      const { settings } = response.data;
      commit('setApplicationFormSettings', settings);
      return response.data;
    });
  },

  getApplicationForms({ commit }, { uuids, lang }) {
    if (lang) commit('setLanguage', lang, { root: true });
    // uuids query params can be single or multiple uuids separated with comma
    uuids = Array.isArray(uuids) ? uuids.join(',') : uuids;
    const config = {
      params: {
        uuids,
      },
    };
    return api.post('api/v2/applications/new', false, config).then((response) => {
      const { forms, data } = response.data;

      commit('setApplicationFormCredentials', data);
      commit('setApplicationFormsNew', forms);
      return response.data;
    });
  },
  getApplicationFormUpdate({ commit }, { slug, token }) {
    return api.get(`api/v2/applications/${slug}/${token}/update`).then((response) => {
      const { forms, data, settings } = response.data;
      commit('setApplicationFormSettings', settings);
      commit('setApplicationFormCredentials', data);
      commit('setApplicationFormsNew', forms);
      return response.data;
    });
  },
  getApplicationFormData({ commit }, params) {
    const {
      id,
      key,
      token,
      slug,
    } = params;
    return api.get(`api/v1/product-form/${id}/update/${slug}/${token}/`).then((response) => {
      const payload = {
        formKey: key,
        data: response.data,
      };
      commit('setApplicationForm', payload);
      return response.data;
    });
  },
  getObjectsListByType(state, params) {
    const {
      id,
      slug,
      token,
      objectType,
    } = params;
    return api.get(`api/v1/product-form/${id}/update/${slug}/${token}/object-list/${objectType}`)
      .then((response) => response.data);
  },
  submitForm(state, params) {
    const {
      id,
      payload,
      token,
      slug,
    } = params;
    return api.post(`api/v1/product-form/${id}/update/${slug}/${token}/`, payload)
      .then((response) => response.data);
  },
  getAdultForm(state, params) {
    const { id, token, slug } = params;
    return api.get(`api/v1/product-form/${id}/update/${slug}/${token}/adult/`)
      .then((response) => response.data);
  },
  postAdultForm(state, params) {
    const {
      id,
      token,
      slug,
      payload,
    } = params;
    return api.post(`api/v1/product-form/${id}/update/${slug}/${token}/adult/`, payload)
      .then((response) => response.data);
  },
  postAdultFormById(state, params) {
    const {
      id,
      token,
      slug,
      personId,
      payload,
    } = params;
    return api.post(`api/v1/product-form/${id}/update/${slug}/${token}/adult/${personId}/`, payload)
      .then((response) => response.data);
  },
  deleteAdult(state, params) {
    const {
      formId,
      token,
      slug,
      personId,
    } = params;
    return api.delete(`api/v1/product-form/${formId}/update/${slug}/${token}/adult/${personId}`)
      .then((response) => response.data);
  },
  getChildrenForm(state, params) {
    const { id, token, slug } = params;
    return api.get(`api/v1/product-form/${id}/update/${slug}/${token}/child/`).then((response) => response.data);
  },
  postChildrenForm(state, params) {
    const {
      id,
      token,
      slug,
      payload,
    } = params;
    return api.post(`api/v1/product-form/${id}/update/${slug}/${token}/child/`, payload)
      .then((response) => response.data);
  },
  postChildrenFormById(state, params) {
    const {
      id,
      token,
      slug,
      personId,
      payload,
    } = params;
    return api.post(`api/v1/product-form/${id}/update/${slug}/${token}/child/${personId}/`, payload)
      .then((response) => response.data);
  },
  deleteChild(state, params) {
    const {
      formId,
      token,
      slug,
      personId,
    } = params;
    return api.delete(`api/v1/product-form/${formId}/update/${slug}/${token}/child/${personId}`)
      .then((response) => response.data);
  },
  getAdultFormById(state, params) {
    const {
      id,
      token,
      slug,
      personId,
    } = params;
    return api.get(`api/v1/product-form/${id}/update/${slug}/${token}/adult/${personId}/`)
      .then((response) => response.data);
  },
  getChildFormById(state, params) {
    const {
      id,
      token,
      slug,
      personId,
    } = params;
    return api.get(`api/v1/product-form/${id}/update/${slug}/${token}/child/${personId}/`)
      .then((response) => response.data);
  },
  getApplicationToCreateAdult(state, params) {
    const { token, slug } = params;
    return api.get(`api/v2/applications/${slug}/${token}/new-adult`).then((response) => response.data);
  },
  postCreditTrust(state, params) {
    const {
      form,
      slug,
      token,
      payload,
    } = params;
    return api
      .post(`api/v1/product-form/${form}/update/${slug}/${token}/credit-trust-check`, payload)
      .then((response) => response.data);
  },
  setMinMaxOccupancyValidation({ commit }, payload) {
    commit('setMinMaxOccupancyValidation', payload);
  },
};
