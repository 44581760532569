<template>
  <div
    class="mdt-radio-list"
    :class="[{ 'has-error': $v.selectedValue.$error }, `list-item-${itemSize}`]">
    <div
      v-if="label"
      class="label">
      <div>
        {{ label }}
        <span
          v-if="!required && !hideOptional"
          class="optional">
          ({{ 'admin_marketing_optional' | translate }})
        </span>
        <i
          v-if="required"
          class="fa-asterisk field-required" />
        <slot name="label2" />
      </div>
      <i
        v-if="tooltip"
        v-tooltip="tooltip"
        class="far fa-info-circle info-icon" />
    </div>
    <ul
      class="radio-list"
      :class="{ 'no-pointerevents': readonly }">
      <li
        v-for="(item, i) in items"
        :key="i"
        :title="item.displayName"
        class="radio-list-item text-cut pointer"
        :class="{
          selected: selectedValue === item.value,
          'equal-width': equalWidth,
        }"
        :style="[hoveredKey, selectedValue].includes(item.value)
          && { backgroundColor: buttonColors[i] }"
        @mouseover="hoveredKey = item.value;"
        @mouseleave="hoveredKey = '';"
        @click="select(item.value)">
        {{ item.displayName }}
      </li>
    </ul>
    <div
      v-if="clientErrors.length"
      class="input-errors">
      <span class="client-errors">
        {{ clientErrors.join('\n') }}
      </span>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import variables from '@/scss/abstract/_variablesExport.module.scss';

export default {
  name: 'MdtRadioList',
  mixins: [validationMixin],
  props: {
    value: {
      required: true,
      validator: (value) => {
        const isString = typeof value === 'string';
        const isBoolean = typeof value === 'boolean';
        const isNumber = typeof value === 'number';
        const isNull = value === null;
        return isString || isBoolean || isNumber || isNull;
      },
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    colors: {
      type: [Boolean, Array],
      default: false,
    },
    equalWidth: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    unset: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
    itemSize: {
      type: String,
      default: 'size-40',
      validator: (value) => {
        const match = ['size-40', 'size-32'];
        return match.includes(value);
      },
    },
    hideOptional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // Set button colors if colors prop is set
    let buttonColors = [];
    if (this.colors === true) {
      buttonColors = [variables.colorSuccess, variables.colorDanger, variables.colorTextSecondary];
    } else if (Array.isArray(this.colors)) {
      buttonColors = this.colors;
    }

    return {
      selectedValue: this.value,
      buttonColors,
      hoveredKey: '',
    };
  },
  computed: {
    clientErrors() {
      const errors = [];
      if (this.$v.selectedValue.$dirty && !this.$v.selectedValue.required) {
        errors.push(this.$options.filters.translate('general_field_is_required'));
      }
      return errors;
    },
  },
  watch: {
    value(value) {
      this.selectedValue = value;
    },
  },
  validations() {
    return {
      selectedValue: {
        required: this.required ? required : false,
      },
    };
  },
  methods: {
    setTouched() {
      this.$v.selectedValue.$touch();
    },
    isValid() {
      this.setTouched();
      return !this.$v.selectedValue.$error;
    },
    select(value) {
      if (this.readonly || (!this.unset && this.selectedValue === value)) return;

      this.setTouched();

      if (this.unset) {
        this.selectedValue = this.selectedValue === value ? null : value;
      } else {
        this.selectedValue = value;
      }

      if (this.selectedValue !== null) {
        this.$emit('input', this.selectedValue);
      } else {
        this.$emit('inputUnset', this.selectedValue);
      }

      // emit mdtDataChanged event so changes could be detected
      this.$emit('mdtDataChanged');
    },
  },
};
</script>

<style lang="scss" scoped>
.mdt-radio-list {
  display: flex;
  flex-direction: column;

  &.has-error {
    color: $color-danger;

    .radio-list {
      .radio-list-item {
        color: $color-danger;
        background-color: rgba($color-danger, 0.1);
      }
    }
  }

  &.list-item-size-40 {
    .radio-list-item {
      height: 40px;
      line-height: 40px;
      padding: 0 18px;
    }
  }

  &.list-item-size-32 {
    .radio-list-item {
      height: 32px;
      line-height: 32px;
      padding: 0 14px;
    }
  }

  .label {
    display: flex;
    margin-bottom: 8px;
    color: $color-text-secondary;
    font-size: 14px;
    line-height: 14px;

    .optional {
      padding-left: 4px;
    }

    .info-icon {
      margin: auto 0 auto 8px;
      color: $color-text-secondary;

      &:hover {
        color: $color-text-primary;
      }
    }

    .field-required {
      color: $color-danger;
      margin-left: 4px;
    }
  }
}

.radio-list {
  display: flex;
  flex-direction: row;
  color: $color-text-secondary;
  font-size: 1rem;
  line-height: 1;

  .radio-list-item {
    margin-right: 8px;
    background-color: $color-back-basic;
    color: $color-text-primary;
    font-size: 16px;
    font-weight: $font-weight-bold;
    border-radius: 4px;

    &:last-child { margin-right: 0;}
    &:hover,
    &.selected {
      color: $color-white;
      background-color: $color-theme;
    }
  }
}

.radio-list-item {
  &:hover,
  &.selected {
    background-color: rgba($color-back-hover, 0.5);
  }

  &.equal-width {
    flex: 1 1 0;
    text-align: center;
  }
}

.input-errors {
  padding-top: 4px;
  font-size: 12px;
  font-weight: $font-weight-normal;
  white-space: pre-line;
}
</style>
